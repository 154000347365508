<template>
  <div class="privacy-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_privacy"
        :hoverInfo="translations.labels.button_add_privacy_hover"
        :onClick="newItem"
      />
    </Action-panel>
    <TableList
      :is-ready="isPageReady"
      :headers="headers"
      :items="model"
      :items-per-page="-1"
      sortable
      hideFooter
      @sorted="saveOrder"
    >
      <template v-slot:item.code="{ item }">
        <v-edit-dialog :return-value.sync="item.code" @save="saveCode(item)">
          {{ item.code }}
          <template v-slot:input>
            <v-text-field
              v-model="item.code"
              label="Edit"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.anonymizeDataTracking="{ item }">
        <tr>
          <td v-if="item.anonymizeDataTracking">
            <div
              class="btn selected"
              @click="setAnonymizeDataTracking(item.id)"
            ></div>
          </td>
          <td v-if="!item.anonymizeDataTracking">
            <div
              class="btn not-selected"
              @click="setAnonymizeDataTracking(item.id)"
            ></div>
          </td>
        </tr>
      </template>
    </TableList>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/dom/ConfirmDialog";
import Vue from "vue";
import Component from "vue-class-component";
import { toastService } from "@/services/toastService.js";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";

@Component({
  inject: {
    privacyService: "privacyService",
  },
  components: {
    TableList,
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    ConfirmDialog,
  },
})
export default class PrivacyList extends Vue {
  isPageReady = false;
  model = [];
  search = "";

  async saveCode(item) {
    try {
      await this.privacyService.updateCode({
        id: item.id,
        code: item.code,
      });
    } catch (e) {
      let msg = this.formatApiErrors(e);
      if (!msg) msg = this.translations.errors.somethingWrong;
      else msg = msg[0];
      toastService.error(msg);
    }
    await this.loadData();
  }

  formatApiErrors(error) {
    let errors = [];
    if (error.code == 422) {
      const fieldsErrorMessages = error.details?.Fields ?? [];
      fieldsErrorMessages.forEach((ef) => {
        errors = [...errors, ...(ef.Errors.map((e) => e.Message) ?? [])];
      });
    } else {
      errors.push(error.message);
    }
    return errors;
  }

  async saveOrder(event) {
    const movedItem = this.model.splice(event.oldIndex, 1);
    this.model.splice(event.newIndex, 0, movedItem[0]);

    let newOrders = [];
    this.model.forEach((m, i) => {
      const index = i + 1;
      this.model[i].priorityOrder = index;
      newOrders.push({
        id: m.id,
        displayOrder: index,
      });
    });

    try {
      await this.privacyService.updateOrder(newOrders);
      await this.loadData();
    } catch (e) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async setAnonymizeDataTracking(id) {
    this.isPageReady = false;
    try {
      await this.privacyService.setAnonymizeDataTracking(id);
      toastService.success(this.translations.success.color_palette_selected);
      this.model = await this.privacyService.list();
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
      this.isPageReady = true;
    }
  }

  async loadData() {
    this.model = await this.privacyService.list();
  }

  init() {
    try {
      this.loadData();
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  toastSuccessLabel(isEnabled) {
    isEnabled
      ? toastService.success(this.translations.success.privacy_disable)
      : toastService.success(this.translations.success.privacy_enable);
  }

  async swapEnabled(idEvent, isEnabled) {
    const status = isEnabled ? "disable" : "enable";
    try {
      await this.privacyService.setEnabled(idEvent, status);
      this.model = await this.privacyService.list();
      this.toastSuccessLabel(isEnabled);
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async del(id) {
    if (
      await this.$refs.confirm.open(
        this.translations.confirmDialog.title,
        this.translations.confirmDialog.message
      )
    ) {
      try {
        await this.privacyService.del(id);
        this.loadData();
      } catch (error) {
        toastService.error(this.translations.errors.somethingWrong);
      }
    }
  }

  newItem() {
    this.$router.push("/privacy/new");
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.privacy_table_code,
        value: "code",
      },
      {
        text: this.translations.labels.privacy_table_displayOrder,
        value: "displayOrder",
      },
      {
        text: this.translations.labels.privacy_table_revision,
        value: "revisions",
        type: "link",
        icon: "mdi-file-multiple",
        formatValue: (item) => {
          return `/privacy/revisions/${item.id}/list`;
        },
      },
      {
        text: this.translations.labels.table_enable,
        value: "enable",
        type: "switch",
        itemValue: (item) => {
          return item.isEnabled;
        },
        onAction: (item) => {
          this.swapEnabled(item.id, item.isEnabled);
        },
      },
      {
        text: this.translations.labels.privacy_table_anonymizeDataTracking,
        value: "anonymizeDataTracking",
      },
      {
        text: this.translations.labels.table_delete,
        value: "delete",
        type: "action",
        icon: "mdi-delete",
        onAction: (item) => {
          this.del(item.id);
        },
      },
    ];
  }

  mounted() {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.privacy_list
    );
  }
}
</script>
<style lang="scss" scoped>
.privacy-list {
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;

    &.selected {
      background: radial-gradient(darkgray 50%, white 60%);
      border: 4px solid darkgray;
      cursor: pointer;
    }

    &.not-selected {
      background-color: white;
      border: 4px solid darkgray;
      cursor: pointer;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
